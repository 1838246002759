import { FunctionComponent, useEffect } from 'react';
import Context from '@ui/components/context';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';

import { ContentPageProps, ContentQueryResult } from './interface';
import {
  TermsComponent,
  ServicesComponent,
  PrivacyComponent,
  PickUpComponent,
  ListYourCarComponent,
  InsuranceComponent,
  HowToCancelComponent,
  HowToBookComponent,
  DocumentsComponent,
  ContactComponent,
  ComplaintPolicyComponent,
  CarStandardComponent,
  RentalTypeComponent,
  CookiesPrivacyComponent
} from './content-component';
import ContentBar from '@pages/help/components/content-bar';
import { trackContentItem } from './tracking';
import { PageSection } from '@utils';
import './style.scss';

const ContentPage = (props: PageWithTranslationProps) => (
  <StaticQuery
    query={graphql`
      query {
        content: allMarkdownRemark(filter: { fields: { collection: { eq: "content" } } }) {
          edges {
            node {
              id
              frontmatter {
                path
                position
                name_th
                name_en
                icon {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                short_description_th
                short_description_en
                rel_canonical_th
                rel_canonical_en
                description_th
                description_en
                keywords_th
                keywords_en
              }
            }
          }
        }
      }
    `}
    render={(data: ContentQueryResult) => (
      <Context>
        <ContentList data={data} {...props} />
      </Context>
    )}
  />
);
const RenderComponent = (prefix: string, urlPath?: string) => {
  if (!urlPath) return <div />;
  switch (urlPath) {
    case 'terms':
      return <TermsComponent prefix={prefix} />;
    case 'services':
      return <ServicesComponent prefix={prefix} />;
    case 'privacy':
      return <PrivacyComponent prefix={prefix} />;
    case 'pick-up':
      return <PickUpComponent prefix={prefix} />;
    case 'list-your-car':
      return <ListYourCarComponent prefix={prefix} />;
    case 'insurance':
      return <InsuranceComponent prefix={prefix} />;
    case 'how-to-cancel':
      return <HowToCancelComponent prefix={prefix} />;
    case 'how-to-book':
      return <HowToBookComponent prefix={prefix} />;
    case 'documents':
      return <DocumentsComponent prefix={prefix} />;
    case 'contact':
      return <ContactComponent prefix={prefix} />;
    case 'complaint-policy':
      return <ComplaintPolicyComponent prefix={prefix} />;
    case 'car-models':
      return <CarStandardComponent prefix={prefix} />;
    case 'rental-type':
      return <RentalTypeComponent prefix={prefix} />;
    case 'cookies-policy':
      return <CookiesPrivacyComponent prefix={prefix} />;
    default:
      return <div />;
  }
};
const ContentList: FunctionComponent<ContentPageProps> = (props) => {
  const { i18n } = useTranslation();
  const { prefix, urlPath } = props.pageContext;
  const { data } = props;
  const contents = data.content.edges;
  const contentTitle = contents.filter((content) => content.node.frontmatter.path === urlPath)[0].node.frontmatter[
    `name_${prefix}`
  ];
  const seoContent = contents.filter((content) => content.node.frontmatter.path === urlPath)[0].node.frontmatter;

  useEffect(() => {
    i18n.changeLanguage(prefix);
  }, [prefix]);

  return (
    <Layout section={PageSection.Help} prefix={prefix} isTransparent={false}>
      <SEO
        title={seoContent[`name_${prefix}`]}
        canonical={seoContent[`rel_canonical_${prefix}`]}
        prefix={prefix}
        path={seoContent.path}
        description={seoContent[`description_${prefix}`]}
        keywords={seoContent[`keywords_${prefix}`]}
        disableAppBanner
      />
      <div className="contents">
        <Container>
          <Row>
            <Tab.Container id="help" defaultActiveKey={urlPath}>
              <Col className="d-none d-lg-block col-12 p-0" lg={3}>
                <ListGroup variant="flush" className="content-list">
                  {contents
                    .sort((a, b) => {
                      return parseInt(a.node.frontmatter.position) - parseInt(b.node.frontmatter.position);
                    })
                    .map(({ node }, i) => (
                      <ListGroup.Item
                        as="a"
                        eventKey={node.frontmatter.path}
                        key={i}
                        className="content-list__item mr-lg-3"
                        href={`/${prefix}/${node.frontmatter.path}`}
                        onClick={(e) => {
                          e.preventDefault();
                          trackContentItem(node.frontmatter[`name_${prefix}`]);
                          navigate(`/${prefix}/${node.frontmatter.path}`);
                        }}
                      >
                        {node.frontmatter[`name_${prefix}`]}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
              <Col className="col-12 px-0 px-lg-3" lg={9}>
                <Tab.Content>
                  <Tab.Pane eventKey={urlPath}>
                    <ContentBar prefix={prefix} title={contentTitle} contents={contents} />
                    <div className="content-wrapper">
                      <h1 className="title">{contentTitle}</h1>
                      {RenderComponent(prefix, urlPath)}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};
export default withTrans()(ContentPage);
