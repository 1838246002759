import loadable from '@loadable/component';
import LoadingBox from '@shares/loading';
import { DefaultProps } from '@pages/help/section/interface';

export const TermsComponent = loadable<DefaultProps>(() => import(`@pages/help/section/terms`), {
  fallback: <LoadingBox />
});

export const ServicesComponent = loadable<DefaultProps>(() => import(`@pages/help/section/services`), {
  fallback: <LoadingBox />
});

export const PrivacyComponent = loadable<DefaultProps>(() => import(`@pages/help/section/privacy`), {
  fallback: <LoadingBox />
});
export const CookiesPrivacyComponent = loadable<DefaultProps>(() => import(`@pages/help/section/cookies-policy`), {
  fallback: <LoadingBox />
});

export const PickUpComponent = loadable<DefaultProps>(() => import(`@pages/help/section/pick-up`), {
  fallback: <LoadingBox />
});

export const ListYourCarComponent = loadable<DefaultProps>(() => import(`@pages/help/section/list-your-car`), {
  fallback: <LoadingBox />
});

export const InsuranceComponent = loadable<DefaultProps>(() => import(`@pages/help/section/insurance`), {
  fallback: <LoadingBox />
});

export const HowToCancelComponent = loadable<DefaultProps>(() => import(`@pages/help/section/how-to-cancel`), {
  fallback: <LoadingBox />
});

export const HowToBookComponent = loadable<DefaultProps>(() => import(`@pages/help/section/how-to-book`), {
  fallback: <LoadingBox />
});

export const DocumentsComponent = loadable<DefaultProps>(() => import(`@pages/help/section/documents`), {
  fallback: <LoadingBox />
});

export const ContactComponent = loadable<DefaultProps>(() => import(`@pages/help/section/contact`), {
  fallback: <LoadingBox />
});

export const ComplaintPolicyComponent = loadable<DefaultProps>(() => import(`@pages/help/section/complaint-policy`), {
  fallback: <LoadingBox />
});

export const CarStandardComponent = loadable<DefaultProps>(() => import(`@pages/help/section/car-models`), {
  fallback: <LoadingBox />
});
export const RentalTypeComponent = loadable<DefaultProps>(() => import(`@pages/help/section/rental-type`), {
  fallback: <LoadingBox />
});
