import { createRef, forwardRef, PureComponent } from 'react';
import { navigate } from 'gatsby';
import { ContentQueryNodeResult } from '@templates/help/interface';
import { canUseDOM } from '@utils';
import './style.scss';

interface ContentBarProps {
  prefix: string;
  title: string;
  contents: ContentQueryNodeResult[];
}

interface ContentBarState {
  open: boolean;
}
export type DropdownRef = HTMLDivElement;

class ContentBar extends PureComponent<ContentBarProps, ContentBarState> {
  state = {
    open: false
  };
  dropdownRef = createRef<DropdownRef>();

  componentWillUnmount() {
    if (!canUseDOM()) return;
    document.documentElement.style.overflow = 'scroll';
  }
  componentDidUpdate(prevProps: ContentBarProps, prevState: ContentBarState) {
    if (prevState.open !== this.state.open) {
      if (!canUseDOM()) return;
      if (this.state.open) document.documentElement.style.overflow = 'hidden';
      else document.documentElement.style.overflow = 'scroll';
    }
  }

  handleToggleDropdown = () => {
    if (this.state.open) return document.addEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  handleOutsideClick = (e: any) => {
    if (this.dropdownRef.current && this.dropdownRef.current.contains(e.target)) return;
    this.setState({ open: false }, () => document.removeEventListener('click', this.handleOutsideClick, false));
  };

  render() {
    const { open } = this.state;
    const { prefix, title, contents } = this.props;
    return (
      <div className="content-bar shadow">
        <div className="content-bar-container">
          <div className={`overlay ${open ? 'activate' : ''}`} />
          <div className="content-bar-item bar-back-btn">
            <a
              id={`help-dropdown-help-center`}
              href={`/${prefix}/help-center`}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/${prefix}/help-center`);
              }}
            >
              <i className="icon-left" />
              ย้อนกลับ
            </a>
          </div>
          <div className="content-bar-item  bar-title">{title}</div>
          <div
            className="content-bar-item bar-other"
            onClick={() => {
              this.setState({ open: !this.state.open }, () => this.handleToggleDropdown());
            }}
            ref={this.dropdownRef}
          >
            หัวข้ออื่นๆ <i className="icon-down" />
            <ContentBarOtherDropdown contents={contents} prefix={prefix} open={open} />
          </div>
        </div>
      </div>
    );
  }
}
interface ContentBarOtherDropdownProps {
  contents: ContentQueryNodeResult[];
  prefix: string;
  open: boolean;
}

export const ContentBarOtherDropdown = forwardRef<DropdownRef, ContentBarOtherDropdownProps>((props, ref) => {
  const { contents, prefix, open } = props;
  return (
    <div className={`other-dropdown ${open ? 'activate' : ''}`} ref={ref}>
      {contents.map((item) => {
        const { node } = item;
        return (
          <a
            id={`help-dropdown-${node.frontmatter.path}`}
            className="other-dropdown__item"
            key={node.frontmatter[`name_${prefix}`]}
            href={`/${prefix}/${node.frontmatter.path}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${prefix}/${node.frontmatter.path}`);
            }}
          >
            {node.frontmatter[`name_${prefix}`]}
          </a>
        );
      })}
    </div>
  );
});

ContentBarOtherDropdown.displayName = 'ContentBarOtherDropdown';

export default ContentBar;
